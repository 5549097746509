import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import dayjs, { Dayjs } from "dayjs";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";

import { userState } from "../../../interface/MainInterface";
import LoadingCircle from "../../../utils/LoadingCircle";
import Toast from "../../../utils/Toast";
import { HttpChannelApi } from "../../../interface/channel-api";

interface propsType {
  userState: userState;
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 3,
  pt: 1,
};

const channelApi = new HttpChannelApi();

const CreateChannel = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const broadTitleRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [broadTitle, setBroadTitle] = useState("");
  const [broadStratTm, setBroadStratTm] = useState<Dayjs | null>(null);
  const [broadEndTm, setBroadEndTm] = useState<Dayjs | null>(null);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenModal(false);
      setBroadTitle("");
      setBroadStratTm(null);
      setBroadEndTm(null);
    };
  }, []);

  const initForm = () => {
    setBroadTitle("");
    setBroadStratTm(null);
    setBroadEndTm(null);
  };

  const open = () => {
    initForm();
    setOpenModal(true);
  };

  const handleBroadStartTmChange = (newValue: Dayjs | null) => {
    setBroadStratTm(newValue);
  };

  const handleBroadEndTmChange = (newValue: Dayjs | null) => {
    setBroadEndTm(newValue);
  };

  const createChannel = async () => {
    // 필수값
    if (broadTitle === "" || broadTitle === undefined) {
      alert("방송명을 입력하세요.");
      return;
    }

    if (broadStratTm === null || !broadStratTm) {
      alert("방송 시작시간을 입력하세요.");
      return;
    }

    if (broadEndTm === null || !broadEndTm) {
      alert("방송 종료시간을 입력하세요.");
      return;
    }

    const param: any = {
      command: "create_channel",
      broad_info: {
        broad_title: broadTitle,
        broad_desc: "",
        broad_start_tm: dayjs(broadStratTm).format("YYYYMMDDHHmm") + "00",
        broad_stop_tm: dayjs(broadEndTm).format("YYYYMMDDHHmm") + "00",
        broad_prod_list: [],
        broad_notice: "",
        vod_url: "",
        vod_started_at: "",
        vod_start_sec: 0,
        vod_end_sec: 0,
        high_start_sec: 0,
        password: "",
        extra_type: "",
        progress_store: "",
        host_id: props.userState.id,
        channel_img: "",
        channel_template: false,
      },
      request_user_id: props.userState.id,
    };

    setIsLoading(true);
    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      props.callback({ command: "finish_crete_channel" });
      setOpenModal(false);
    } else {
      toastRef.current?.toast(res.result_body, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Tooltip title="창닫기">
                <IconButton
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CloseIcon className="color-red" />
                </IconButton>
              </Tooltip>
            </Box>
            <Stack direction="column" spacing={2}>
              <TextField
                inputRef={broadTitleRef}
                value={broadTitle}
                label="방송명"
                placeholder="방송명을 입력하세요."
                variant="outlined"
                fullWidth
                required
                type="search"
                inputProps={{ enterKeyHint: "Enter" }}
                onChange={(e) => {
                  setBroadTitle(e.target.value);
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
                  <DateTimePicker
                    label="시작시간"
                    value={broadStratTm}
                    onChange={handleBroadStartTmChange}
                    slotProps={{
                      textField: { fullWidth: true },
                    }}
                  />
                  <DateTimePicker
                    label="종료시간"
                    value={broadEndTm}
                    onChange={handleBroadEndTmChange}
                    slotProps={{
                      textField: { fullWidth: true },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  createChannel();
                }}
              >
                방송 생성
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(CreateChannel);
